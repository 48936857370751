import { Element } from "@/scripts/extends";

export default class LinkCheck extends Element {
	mounted() {
		this.links = this.el.querySelectorAll('a[data-track-name]');

		if (this.links) {
			this.links.forEach(el => {
				el.addEventListener("click", (e) => this.onClick(el, e));
			});
		}
	}

	onClick(el, e) {
		let dataId = el.getAttribute('data-track-id');
		let dataText = el.getAttribute('data-track-name');
		let dataFollow = el.getAttribute('data-track-follow');

		if (window.dataLayer) {
			window.dataLayer.push({
				"event": "follow_click",
				"link_id": dataId,
				"link_follow": dataFollow,
				"link_name": dataText,
				"page_url": window.location.href,
			});
		}
	}

	destroy() {
		this.links.forEach(el => {
			el.removeEventListener("click", () => this.onClick());
		});
	}
}
